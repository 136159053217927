import React, { useEffect, useState } from "react";
import {
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useHistory } from "react-router-dom";

const BotonHeader = (props) => {
  const { link, text, setClose } = props;
  const [validate, setValidate] = useState(false);
  const matches = useMediaQuery("(min-width:960px)");

  const useStyles = makeStyles((theme) => ({
    botonDesactivado: {
      minWidth: 30,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      height: "58px",
      padding: 0,
      margin: "15px 15px",
      transition: "all 0.3s ease-in-out",
      color: "#DAD1C9",
      borderWidth: "0%",
      "&:hover": {
        transition: "all 0.3s ease-in-out",
        backgroundColor: "transparent !important",
        "& hr": {
          width: "40px",
          maxWidth: "40px",
          opacity: 1,
          transition: "all 0.3s ease-in-out",
          height: "3px",
        },
      },
      [theme.breakpoints.down("1100")]: {
        marginLeft: "15px !important",
      },

    },
    botonActivado: {
      minWidth: 30,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      height: "58px",
      padding: 0,
      margin: "15px 15px",
      transition: "all 0.3s ease-in-out",
      color: "#DAD1C9",
      borderWidth: "0%",
      "& p": {
        fontWeight: 700,
      },
      "& hr": {
        width: "40px",
        maxWidth: "40px",
        opacity: 1,
        transition: "all 0.3s ease-in-out",
        height: "3px",
      },

      [theme.breakpoints.down("1100")]: {
        // height: "50px !important",
        marginLeft: "15px !important",
      },
      [theme.breakpoints.down("sm")]: {
        // height: "50px",
      },
    },
    texto: {
      fontFamily: "URegular",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "1.75px",
      color: "#fff",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      padding: "20px 0px",
      width: "100%",
      "&:hover": {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("1100")]: {
        fontSize: "16px",
      },
    },
    subrayado: {
      width: "10%",
      opacity: 0,
      margin: "auto",
      border: "0px",
      height: "3px",
      backgroundColor: "#268F43",
      color: "#268F43",
      position: "absolute",
      bottom: 0,
      transition: "all 0.3s ease-in-out",
    },
    imgTienda: {
      height: 24,
      width: 24,
      marginRight: 10,
    },
    link: {
      height: "64px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    updateCurrentPath(); // eslint-disable-next-line
  }, [window.location.pathname]);

  const updateCurrentPath = () => {
    if (link === window.location.pathname) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };
  const onClick = () => {
    if (matches === false) {
      setClose(false);
    }
  };
  return (
    <React.Fragment>
      <Link className={classes.link} to={link} component={RouterLink}>
        <Button
          className={
            validate ? classes.botonActivado : classes.botonDesactivado
          }
          onClick={() => {
            history.push(link);
            onClick();
          }}

          // onMouseEnter={() => setChecked(true)}
          // onMouseLeave={() => setChecked(false)}
        >
          <Typography className={classes.texto}>{text}</Typography>
          <hr className={classes.subrayado} />
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default BotonHeader;
