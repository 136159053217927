import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import BotonHeader from "./BotonHeader";
import MenuMobile from "./MenuMobile";
import { gsap } from "gsap";
import ScrollToTop from "./ScrollToTop";

const useStyles = makeStyles((theme) => ({
  barraHeader: {
    width: "100%",
    height: "72px",
    background: "#ffffff",
    boxShadow:"none",
    [theme.breakpoints.up("md")]: {
      height: "94px",
      boxShadow: "0px 5px 5px #00000029",
      marginTop: -4,
    },
  },
  containerToobar: {
    height: "90px",
    width: "100%",
    background:
      "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 47%, rgba(143,195,118,1) 51%, rgba(143,195,118,1) 100%)",
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  toolbar: {
    position: "relative",
    backgroundColor: "#8FC376",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 1080,
    height: "100%",
    margin: "auto",
  },
  imgFirma: {
    height: 40,
    width: "auto",
    position: "absolute",
    left: 40,
    top: 10,
    [theme.breakpoints.up("md")]: {
      height: 50,
      top: 10,
      left: "0%",
    },
  },
  lineaVerde: {
    position: "absolute",
    height: 95,
    left: -90,
    bottom: -23,
    // transform: "rotate(180deg)",
    transform: "rotateY(180deg);transform:rotateX(180deg)",
    [theme.breakpoints.up("md")]: {
      transform: "rotate(0deg)",
      position: "absolute",
      height: 120,
      left: -180,
      bottom: -1,
    },
  },
  textoIdiomas: {
    fontFamily: "Europa-Regular",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#EFEDE9",
    textAlign: "center",
    justifyItems: "center",
    maxWidth: "360px",
  },
  idiomas: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    height: "16px",
    top: 10,
    right: 10,
    left: "auto",
  },
  bottonIdioma: {
    padding: "0px 0px !important",
    minWidth: "auto !important",
  },
  botonera: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    bottom: 0,
    // padding: "80px 0px 0px",
    justifyContent: "space-between",
    maxWidth: 1130,
    marginInline: "auto",
    marginBottom: 10,
    [theme.breakpoints.down("1100")]: {
      justifyContent: "space-evenly",
    },
  },
  botones: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  linea: {
    // ARREGLAR ESTO backgroundImage: `url(${require("../assets/legal/linea.png").default})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "5px",
    heigh: "100%",
    padding: "0px 10px",
  },
  botonMenu: {
    position: "absolute",
    top: "45%",
    right: "5%",
    height: "10px",
    width: "15px",
    zIndex: 9999999,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  textoBoton: {
    fontFamily: "Museo700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#EFEDE9",
    textAlign: "center",
    justifyItems: "center",
  },
  link: {
    textDecoration: "none",
    marginRight: "10px",
    "&:hover": {
      textDecoration: "none",
    },
  },
  main: {
    paddingTop: 60,
    [theme.breakpoints.up("md")]: {
      // paddingTop: 90,
    },
  },
}));

const HeaderHome = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:960px)");
  const [openDrawer, setOpenDrawer] = useState(false);
  const refToolbar = useRef();
  useEffect(() => {
    onLoaded();
  }, []);
  const onLoaded = () => {
    let timeLine = gsap.timeline({});
    timeLine.from(refToolbar.current, {
      ease: "power3.out",
      duration: 0.5,
      opacity: 0,
    });
  };
  const handleDrawerOpen = () => {
    console.log("abrio");
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    console.log("cerro");
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.barraHeader}>
        <div className={classes.containerToobar}>
          <Toolbar id="toolbar" ref={refToolbar} className={classes.toolbar}>
            <img
              className={classes.lineaVerde}
              src={require("../assets/header/curvamenu.svg").default}
              alt={"linea-verde"}
            />
            <Hidden smDown>
              <Grid
                container
                alignItems="center"
                justifyContent={matches === true ? "space-between" : "center"}
                className={classes.botonera}
              >
                <Link
                  className={classes.link}
                  to={{ pathname: "/" }}
                  component={RouterLink}
                  onClick={handleDrawerClose}
                >
                  <img
                    className={classes.imgFirma}
                    src={require("../assets/home/logoGenotipos.png").default}
                    alt={"logo-genotipos"}
                  />
                </Link>

                <nav className={classes.botones}>
                  <BotonHeader link="/que-hacemos" text="QUÉ HACEMOS" />
                  <BotonHeader link="/produccion" text="PRODUCCIÓN" />
                  <BotonHeader link="/innovaciones" text="INNOVACIONES" />
                  <BotonHeader link="/clientes" text="CLIENTES" />
                  <BotonHeader link="/contacto" text="CONTACTO" />
                </nav>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Link
                  className={classes.link}
                  to={{ pathname: "/vinos" }}
                  component={RouterLink}
                  onClick={handleDrawerClose}
                >
                  <img
                    className={classes.imgFirma}
                    height={24}
                    width={138}
                    src={require("../assets/header/firma.png").default}
                    alt={"firma Susana"}
                  />
                </Link>
                <div style={{ alignItems: "center", justifyItems: "center" }}>
                  <Button
                    className={classes.botonMenu}
                    onClick={() => setOpenDrawer(!openDrawer)}
                  >
                    {openDrawer === false ? (
                      <Typography className={classes.textoBoton}>
                        MENÚ
                      </Typography>
                    ) : (
                      <Typography className={classes.textoBoton}>X</Typography>
                    )}
                  </Button>
                </div>
              </div>
            </Hidden>
          </Toolbar>
        </div>
      </AppBar>
      <Hidden mdUp>
        <MenuMobile
          openDrawer={openDrawer}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
        <div className={classes.logo} />
      </Hidden>
      <ScrollToTop>
        <main className={classes.main}>{props.children}</main>
      </ScrollToTop>
    </React.Fragment>
  );
};

export default HeaderHome;
