import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import loadable from "@loadable/component";
import RouteWithHeader from "./components/RouteWithHeader"
// const ParallaxTry = loadable(() => import("./components/ParallaxTry"))
const Home = loadable(() => import("./views/Home/Home"));
const QueHacemos = loadable(() => import("./views/QueHacemos/HomeQueHacemos"))
const Clientes = loadable(() => import("./views/Cliente/HomeClientes"))
const Contacto = loadable(() => import("./views/Contacto/Contacto"))
const Innovaciones = loadable(() => import("./views/Innovaciones/Innovaciones"))
const Produccion = loadable(() => import("./views/Produccion/Produccion"))
function App() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/ParallaxTry" component={ParallaxTry} /> */}
        {/* <Route exact path="/app" component={Appsw}/> */}
        <RouteWithHeader exact path="/" component={Home} />
        <RouteWithHeader exact path="/que-hacemos" component={QueHacemos} />
        <RouteWithHeader exact path="/contacto" component={Contacto} />
        <RouteWithHeader exact path="/clientes" component={Clientes} />
        <RouteWithHeader exact path="/innovaciones" component={Innovaciones}/>
        <RouteWithHeader exact path="/produccion" component={Produccion}/>
     
        {/* section Sobre Nosotros */}
        <Redirect to="/" />
        {/* si el path no hace match con alguna de las rutas anteriores lo redirige al home */}
      </Switch>
    </Router>
  );
}

export default App;
