import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import { Typography, Button } from "@material-ui/core";
import BotonHeader from "./BotonHeader";

const styleDrawer = (theme) => ({
  root: {
    "& div.MuiDrawer-paperAnchorTop": {
      top: 0,
      height: "100vh",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    "& div.MuiPaper-root": {
      backgroundColor: "#8FC376",
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "FLEX-START",
      paddingTop: "100px",
      overflow: "scroll",
      transitionDuration: "1s !important",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        maxWidth: 500,
      },
    },
  },
});
const CustomDrawer = withStyles(styleDrawer)(Drawer);
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
    position: "relative",
  },
  imgFirma: {
    position: "relative",
    top: "30%",
    left: "5%",
    height: 32,
    width: "auto",
    [theme.breakpoints.up("md")]: {
      height: 50,
    },
  },
  containerLogoMenu: {
    top: "0%",
    width: "100%",
    backgroundColor: "#8FC376",
    height: "60px",
    position: "fixed",
    zIndex: 10,
    padding: "0 40px",
  },
  botonMenu: {
    position: "absolute",
    top: "50%",
    right: "5%",
    height: "10px",
    width: "15px",
    zIndex: 9999999,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  textoBoton: {
    fontFamily: "Museo700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#EFEDE9",
    textAlign: "center",
    justifyItems: "center",
  },
}));
const MenuMobile = ({ openDrawer, handleDrawerClose, handleDrawerOpen }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomDrawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openDrawer}
      >
        <div className={classes.containerLogoMenu}>
          <Link
            className={classes.link}
            to={{ pathname: "/vinos" }}
            component={RouterLink}
            onClick={handleDrawerClose}
          >
            <img
              className={classes.imgFirma}
              height={30}
              width={178}
              src={require("../assets/header/firma.png").default}
              alt={"firma Susana"}
            />
          </Link>
          <Button
            className={classes.botonMenu}
            onClick={() => handleDrawerClose(false)}
          >
            <Typography className={classes.textoBoton}>X</Typography>
          </Button>
        </div>
        {openDrawer && (
          <List style={{ width: "100%", padding: "0 40px" }}>
            <nav className={classes.botones}>
              <BotonHeader
                link={"/que-hacemos"}
                text="QUÉ HACEMOS"
                setClose={handleDrawerClose}
              />
              <BotonHeader
                link="/produccion"
                text="PRODUCCIÓN"
                setClose={handleDrawerClose}
              />
              <BotonHeader
                link="/innovaciones"
                text="INNOVACIONES"
                setClose={handleDrawerClose}
              />
              <BotonHeader
                link="/clientes"
                text="CLIENTES"
                setClose={handleDrawerClose}
              />
              <BotonHeader
                link="/contacto"
                text="CONTACTO"
                setClose={handleDrawerClose}
              />
            </nav>
          </List>
        )}
        <Divider />
      </CustomDrawer>
    </React.Fragment>
  );
};

export default MenuMobile;
